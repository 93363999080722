<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="add" class="mr-1" /><strong>{{ translate('add_planning') }}</strong>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent>
                    <b-list-group-item class="p-0">
                        <b-list-group-item>
                            <div class="row m-0 text-muted align-items-center">
                                <div class="col-2 col-md-auto pl-0 text-center"><i class="fas fa-swimmer fa-2x"></i></div>
                                <div class="col-10 col-md">
                                    <small>{{ translate('service') }}</small><br />
                                    <strong>{{ service.name }}</strong>
                                </div>
                                <div class="col-2 col-md-auto pl-0 text-center"><i class="fas fa-map-marker-alt fa-2x"></i></div>
                                <div class="col-10 col-md">
                                    <small>{{ translate('location') }}</small><br />
                                    <strong>{{ location.name }}</strong>
                                </div>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item class="p-0">
                            <div class="row m-0">
                                <div class="col-md-6 p-0">
                                    <q-input type="text" :label="translate('name')" color="primary" square dense id="name" v-model="planning.name" />
                                </div>
                                <div class="col-md-6 p-0">
                                    <q-input :label="translate('max_clients')" color="primary" square dense v-model.number="planning.max_clients" @wheel="$event.target.blur()" type="number" id="max_clients"></q-input>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-md p-0">
                                    <q-input :label="translate('start_date')" square dense readonly id="start_date" v-model="planning.start_date">
                                        <template v-slot:append>
                                            <q-icon name="event"></q-icon>
                                        </template>
                                    </q-input>
                                </div>
                                <div class="col-md p-0 align-items-stretch d-flex">
                                    <label tabindex="-1" class="outlined-checkbox-bottom p-2 cursor-pointer" style="margin-left: 15px; width: calc(100% - 30px);">
                                        <span class="pull-left pr-2">
                                            <q-checkbox class="m-0" dense @input="updateEndDate" size="md" color="primary" v-model="is_recurring_planning" id="recurring_planning" />
                                        </span>
                                        <span>{{ translate('recurring_planning') }}</span>
                                    </label>
                                </div>
                            </div>
                        </b-list-group-item>
                        <q-slide-transition>
                            <div v-show="is_recurring_planning">
                                <div class="row m-0">
                                    <div class="col-md-6 p-0">
                                        <div class="pl-3 pt-1 pb-0 text-muted">
                                            {{ translate('end_date') }}
                                        </div>
                                        <b-list-group-item class="p-1">
                                            <div class="row m-0">
                                                <div class="col-auto">
                                                    <q-radio class="width-auto" size="md" color="primary" v-model="planning.is_infinite" :val="true" id="is_infinite" :label="translate('infinite')" />
                                                </div>
                                                <div class="col-auto">
                                                    <q-radio class="width-auto" size="md" color="primary" v-model="planning.is_infinite" :val="false" id="predefined_date" :label="translate('predefined_date')" />
                                                </div>
                                            </div>
                                        </b-list-group-item>
                                        <q-slide-transition>
                                            <div v-show="!planning.is_infinite">
                                                <q-input :label="translate('end_date')" color="primary" square dense :readonly="is_recurring_planning && !planning.is_infinite ? false : true" id="end_date" v-model="planning.end_date">
                                                    <template v-slot:append>
                                                        <q-icon name="event" :class="is_recurring_planning && !planning.is_infinite ? 'cursor-pointer' : 'text-grey-6'">
                                                            <q-popup-proxy ref="qEndDateProxy" v-if="is_recurring_planning && !planning.is_infinite" transition-show="scale" transition-hide="scale">
                                                                <q-date @input="(val) => onUpdateEndDate(planning.end_date)" first-day-of-week="1" :options="setSelectableEndDays(planning.start_date)" v-model="planning.end_date" mask="YYYY-MM-DD" />
                                                            </q-popup-proxy>
                                                        </q-icon>
                                                    </template>
                                                </q-input>
                                            </div>
                                        </q-slide-transition>
                                    </div>
                                    <div class="col-md-6 p-0" v-if="!$q.screen.lt.sm">
                                        <q-item class="pl-0 mb-2">
                                            <q-item-section>
                                                <q-select class="m-0" flat square color="info" :label="translate('select_timelimit')" :options="timelimit_options" v-model="planning.selected_timelimit">
                                                    <template v-slot:prepend>
                                                        <i class="fas fa-clock"></i>
                                                    </template>
                                                </q-select>
                                            </q-item-section>
                                            <q-item-section class="p-0" side>
                                                <q-btn size="0.65rem" type="button" @click.stop.prevent="showAddTimeLimitPanel" color="primary" round dense no-caps icon="add">
                                                    <q-tooltip :offset="[10, 10]">{{ add_timelimit_title }}</q-tooltip>
                                                </q-btn>
                                            </q-item-section>
                                        </q-item>
                                    </div>
                                </div>
                            </div>
                        </q-slide-transition>
                        <div class="row m-0">
                            <div class="col p-0">
                                <q-input :label="translate('start_time')" color="primary" square dense v-model="planning.start_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time" class="cursor-pointer">
                                            <q-popup-proxy transition-show="scale" transition-hide="scale" ref="qStartTimeProxy">
                                                <q-time class="time-menu" v-model="planning.start_time" mask="HH:mm" format24h @input="(val) => onUpdateStartTime(planning.start_time)" />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                            <div class="col p-0">
                                <q-input :label="translate('end_time')" color="primary" square dense v-model="planning.end_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time" class="cursor-pointer">
                                            <q-popup-proxy transition-show="scale" transition-hide="scale" ref="qEndTimeProxy">
                                                <q-time class="time-menu" v-model="planning.end_time" mask="HH:mm" format24h @input="(val) => onUpdateEndTime(planning.end_time)" />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <b-list-group-item class="p-0 pb-2" v-if="$q.screen.lt.sm">
                            <q-item class="pl-0 pt-0 pb-0 mb-2">
                                <q-item-section>
                                    <q-select class="m-0 p-0" flat square color="info" :label="translate('select_timelimit')" :options="timelimit_options" v-model="planning.selected_timelimit">
                                        <template v-slot:prepend>
                                            <i class="fas fa-clock"></i>
                                        </template>
                                    </q-select>
                                </q-item-section>
                                <q-item-section class="pl-0" side>
                                    <q-btn size="0.65rem" type="button" @click.stop.prevent="showAddTimeLimitPanel" color="primary" round dense no-caps icon="add">
                                        <q-tooltip :offset="[10, 10]">{{ add_timelimit_title }}</q-tooltip>
                                    </q-btn>
                                </q-item-section>
                            </q-item>
                        </b-list-group-item>
                    </b-list-group-item>
                    <b-list-group-item class="pt-2 pb-2 font-weight-bold">
                        {{ translate('providers') }}
                    </b-list-group-item>
                    <b-list-group-item class=" t-1">
                        <div class="font-weight-bold mt-1">{{ translate('included_providers') }} ({{ planning_included_providers.length }})</div>
                        <template v-if="planning_included_providers && planning_included_providers.length">
                            <q-item class="bordered-item" clickable ripple v-for="(provider,i) in planning_included_providers" :key="'included-provider-' + i + provider.id">
                                <q-item-section avatar>
                                    <q-avatar class="shadow-2">
                                        <img v-if="provider && provider.image_link" :src="provider.image_link">
                                        <i v-else class="fas fa-user fa-1x text-muted"></i>
                                    </q-avatar>
                                </q-item-section>
                                <q-item-section>
                                    <q-item-label>{{ provider.name }}</q-item-label>
                                </q-item-section>
                                <q-item-section side>
                                    <q-btn dense icon="delete" @click.stop.prevent="deletePlanningProviderFromIncluded(provider, 'provider')" flat color="danger" />
                                </q-item-section>
                            </q-item>
                        </template>
                        <div>
                            <div class="p-t2 mt-2 mb-2 text-primary bg-light">
                                <q-icon name="add" />&nbsp;
                                {{ translate('add_provider') }}
                            </div>
                            
                            <div v-if="!workers.length || workers.length === 0" class="text-muted">{{ translate('no_results') }}</div>
                            <div v-else class="text-muted">{{ translate('results_found') }}: {{ workers.length }}</div>

                            <q-input class="search-input mb-0" @input="isTypingWorker = true" v-model="searchWorker" id="filternameitem" type="text" :placeholder="translate('find_worker')" :dense="true">
                                <template v-slot:prepend>
                                    <q-icon name="search" />
                                </template>
                            </q-input>
                            <div class="items-container list-container-scroll vh-31" :class="'list_wraper-planning-' + planning.id">
                                <q-item tag="label" class="list-item full-width avatar-red-hover" clickable ripple v-for="(worker,i) in workers" :key="'list-item-' + i + '-' + worker.id">
                                    <q-item-section avatar>
                                        <q-avatar class="shadow-2">
                                            <img v-if="worker && worker.image_link" :src="worker.image_link">
                                            <i v-else class="fas fa-user fa-1x text-muted"></i>
                                        </q-avatar>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-item-label>
                                            {{ worker.name }}
                                        </q-item-label>
                                    </q-item-section>
                                    <q-item-section side>
                                        <q-btn icon="add" @click.stop.prevent="includeWorker(worker)" dense flat color="primary" />
                                    </q-item-section>
                                </q-item>
                            </div>
                            <infinite-loading @infinite="getWorkers" ref="infiniteLoading" :identifier="'to-include-workers-planning-' + planning.id + '-' +infWorkers" slot="append" />
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerPlanning">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
    <!-- Trigger overLap panel. -->
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../../main'
import {
    QTime,
    QDate,
    QSlideTransition
} from 'quasar'
import AddTimeLimit from '../../../business/time-limits/forms/AddTimeLimit'
import OverlapDetails from '../../../template-parts/OverlapDetails'

export default {
    name: 'AddPlanning',
    props: ['service', 'location', 'event', 'event_date', 'event_minute', 'event_hour'],
    components: {
        QTime,
        QDate,
        QSlideTransition
    },
    created: function () {
        this.setDefaultDate()
        this.setDefaultTime()
        this.getServiceInfo()
        this.getTimeLimits()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    computed: {
        add_timelimit_title: function () {
            return this.translate('new_timelimit')
        }
    },
    data: function() {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            workers: [],
            page_workers: 1,
            state: {},
            infWorkers: +new Date(),
            isTypingWorker: false,
            searchWorker: '',
            planning_included_providers: [],
            is_recurring_planning: true,
            infinite_string: 'infinite',
            planning: {
                name: null,
                is_infinite: true,
                start_date: '',
                end_date: '',
                start_time: '',
                end_time: '',
                max_clients: '',
                selected_timelimit: null
            },
            timelimit_options: [],
            msg: '',
        };
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        showOverlapPanel: function (data) {
            const panelInstance = this.$showPanel({
                component: OverlapDetails,
                props: {
                    data: data
                }
            })
        }, 
        onUpdateEndDate: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qEndDateProxy.hide()
            })
        },
        onUpdateStartTime: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qStartTimeProxy.hide()
            })
        },
        onUpdateEndTime: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qEndTimeProxy.hide()
            })
        },
        resetMainListWorkers: function () {
            this.page_workers = 1
            this.workers = []
            this.infWorkers++
        },
        getWorkers: function ($state) {
            var url = baseUrl + 'services/providers?page=' + this.page_workers + '&items_per_page=' + this.$items_per_page + '&service_id=' + this.service.id
            if (this.searchWorker) {
                url += '&search=' + this.searchWorker
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        this.workers.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            array['main_image_link'] = entity.main_image_link ? baseUrl + entity.main_image_link : null

                            return array
                        }))
                        if (response.data.next_page === true) {
                            this.page_workers++
                            $state.loaded()
                        } else {
                            $state.complete()
                            if (this.streaming_included_items && this.streaming_included_items.length) {
                                this.streaming_included_items.map(included_id => {
                                    if (this.workers.filter((item) => {
                                            item.id === included_id
                                        })) {
                                        // Find index of specific object using findIndex method,
                                        var objIndex = this.items.findIndex((obj => obj.id === included_id))
                                        // and remove the element from the items array.
                                        this.items.splice(objIndex, 1)
                                    }
                                })
                            }
                        }
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        includeWorker: function (item) {
            this.planning_included_providers.push(item)
            this.deleteFromTOIncludeList(item.id)
            return false
        },
        addTOToIncludeList: function (item) {
            this.workers.push(item)
            return false
        }, 
        deleteFromTOIncludeList: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.workers.findIndex((obj => obj.id === id))
            // and remove the element from the workers array.
            this.workers.splice(objIndex, 1)
        },
        showAddTimeLimitPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddTimeLimit,
                props: {
                    add_item_title: this.add_timelimit_title,
                    post_route: baseUrl + 'time_limits',
                    entity_type: 'timelimit',
                }
            })
            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetTimelimitsList()
                }
            })
        },
        resetTimelimitsList: function () {
            this.timelimits = []
            this.getTimeLimits()
        },
        setSelectableEndDays: function(date) {
            var dayWeekIndex = new Date(date).getDay()
            return date => new Date(date).getDay() === dayWeekIndex
        },
        updateEndDate: function () {
            if (!this.is_recurring_planning) {
                this.planning.end_date = this.event_date
            }
        },
        setDefaultDate: function () {
            this.planning.start_date = this.event_date
            this.planning.end_date = this.planning.start_date
        },
        getTimeLimits: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(baseUrl + 'time_limits?only_active=1', {
                    headers: headers
                })
                .then(response => {
                    var timelimits = response.data.items.map(entity => {
                        var value = ''
                        if (entity.day_before_time != 'None') {
                            value = entity.day_before_time
                        }
                        if (entity.min_hours_before) {
                            value = entity.min_hours_before
                        }

                        let entity_label = this.translate(entity.limit_type) + ': ' + value
                        let array = {'value': entity.id, 'label': entity_label}
                        return array
                    })
                    this.timelimit_options = timelimits
                    this.planning.selected_timelimit = timelimits[0]
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getServiceInfo: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(baseUrl + 'services/' + this.service.id, {
                    headers: headers
                })
                .then(response => {
                    var service = response.data.item
                    if (service.max_clients) {
                        this.planning.max_clients = service.max_clients
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        setDefaultTime: function () {
            var subtime = ':00'
            if (this.event_minute > 30) {
                subtime = ':30'
            }
            var meridian = ' AM'
            if (this.hour >= 12) {
                meridian = ' PM'
            }
            meridian = ''
            var event_hour = parseInt(this.event_hour)
            var event_start_hour = event_hour < 10 ? '0' + event_hour : event_hour
            var event_end_hour = (event_hour + 1) < 10 ? '0' + (event_hour + 1) : (event_hour + 1)

            this.planning.start_time = event_start_hour + subtime + meridian
            this.planning.end_time = event_end_hour + subtime + meridian
        },
        registerPlanning: function () {
            //temp - need to abort if accessToken() is null
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }

            if (this.planning.selected_timelimit == null) {
                var message = 'add_planning_required_timelimit'
                var translated_message = this.translate(message)
                this.$toasted.error(translated_message, {
                    duration: this.$toasted_duration
                })
                return
            }

            var data = {
                'name': this.planning.name,
                'service_id': this.service.id,
                'location_id': this.location.id,
                'start_date': this.planning.start_date,
                'start_time': this.planning.start_time + ':00',
                'end_time': this.planning.end_time + ':00',
                'max_clients': this.planning.max_clients,
                'time_limit_id': this.planning.selected_timelimit.value
            }
            
            var worker_ids = []
            if (this.planning_included_providers && this.planning_included_providers.length) {
                this.planning_included_providers.forEach(element => {
                    worker_ids.push(element.worker_id)
                })
            }
            data['worker_ids'] = worker_ids
            
            if (this.planning.is_infinite === true) {
                // Do nothing, we do not need to send end_date for this case in POST.
            } else {
                data['end_date'] = this.planning.end_date
            }

            if (!this.is_recurring_planning) {
                data['end_date'] = this.planning.start_date
            }
            
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.post(baseUrl + 'plannings', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg;
                    if (response.data.status) {
                        var message = 'add_planning_' + this.msg
                        var translated_message = this.translate(message)
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                        eventBus.$emit('update_plannings', {
                            status: true
                        })
                    } else {
                        var key = response.data.key ? '_' + response.data.key : ''
                        var message = 'add_planning_' + this.msg + key
                        var translated_message = this.translate(message)
                        if (response.data.msg && _.endsWith(response.data.msg, '_overlap')) {
                            // open new panel with overlap details displayed.
                            this.showOverlapPanel(response.data)
                        }
                        else {
                            this.$toasted.error(translated_message, {
                                duration: this.$toasted_duration
                            })
                        }
                    }

                })
                .catch(error => {
                    this.$toasted.error('An error has occured', {
                        duration: this.$toasted_duration
                    })
                })
        },
        deletePlanningProviderFromIncluded: function (item, item_type) {
            if (item_type === 'provider') {
                var objIndex = this.planning_included_providers.findIndex((obj => obj.id === item.id))
                this.planning_included_providers.splice(objIndex, 1)
                this.addTOToIncludeList(item)
            }
            return false
        },
        addProvidersToList: function (items) {
            this.planning_included_providers = items && items.length ? items : []
            return false
        },      
        closePanel() {
            this.$emit('closePanel', {
                response: 'dsfd'
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}

.disabled {
    pointer-events: none;
}
</style>
