<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="check" class="mr-1" /><strong>{{ translate('activate_planning_title') }}</strong>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent="activatePlanning" style="overflow: hidden;">
                    <b-list-group-item class="list-group-item-accent-info">
                        <div class="row m-0 text-muted align-items-center">
                            <div class="col-2 col-md-auto p-0 text-center"><i class="fas fa-calendar fa-2x"></i></div>
                            <div class="col-10 col-md">
                                <small>{{ translate('start_date') }}</small><br />
                                <strong>{{ planning.start_date }}</strong>
                            </div>
                            <div class="col-2 col-md-auto p-0 text-center"><i class="fas fa-swimmer fa-2x"></i></div>
                            <div class="col-10 col-md">
                                <small>{{ translate('service') }}</small><br />
                                <strong>{{ service.name }}</strong>
                            </div>
                            <div class="col-2 col-md-auto p-0 text-center"><i class="fas fa-map-marker-alt fa-2x"></i></div>
                            <div class="col-10 col-md">
                                <small>{{ translate('location') }}</small><br />
                                <strong>{{ location.name }}</strong>
                            </div>
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-toggle v-if="!planning.is_active" @input="activatePlanning" v-model="planning.is_active" dense keep-color checked-icon="checked" unchecked-icon="clear" :label="translate('activate_planning')" />
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {
    QTime,
    QDate
} from 'quasar'
import DeletePlanning from '../forms/DeletePlanning'

export default {
    name: 'ActivatePlanning',
    props: ['service', 'location', 'event'],
    components: {
        QTime,
        QDate
    },
    data: function() {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            is_recurring_planning: true,
            planning: {
                inactive_planning_id: 0,
                start_date: '',
                is_active: false
            },
            msg: '',
        };
    },
    created: function () {
        this.setInitialValues()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        setInitialValues: function () {
            this.planning.start_date = this.event.date
        },
        activatePlanning: function () {
            //temp - need to abort if accessToken() is null
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            
            axios.delete(baseUrl + 'plannings/inactive/' + this.event.inactive_planning_id, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'activate_planning_' + this.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg;
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}
</style>
