<template>
<div class="fit sticky-form-actions edit-panel">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div :class="'pull-right'">
                    <q-btn class="mr-2" :label="!$q.screen.lt.sm ? translate('delete') : ''" no-caps dense color="danger" @click.prevent="showDeletePlanningPanel" icon="delete" flat />
                    <q-toggle @input="inactivatePlanning" v-model="planning.is_active" dense keep-color checked-icon="checked" unchecked-icon="clear" :label="prettyDate(planning.event_date)" />
                </div>
                <div class="m-1"><q-icon name="edit" class="mr-1" />{{ translate('edit_planning') }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent style="overflow: hidden;">
                    <b-list-group-item class="list-group-item-accent-info p-0">
                        <b-list-group-item>
                            <div class="row m-0 text-muted align-items-center">
                                <div class="col-2 col-md-auto pl-0 text-center"><i class="fas fa-swimmer fa-2x"></i></div>
                                <div class="col-10 col-md">
                                    <small>{{ translate('service') }}</small><br />
                                    <strong>{{ service.name }}</strong>
                                </div>
                                <div class="col-2 col-md-auto pl-0 text-center"><i class="fas fa-map-marker-alt fa-2x"></i></div>
                                <div class="col-10 col-md">
                                    <small>{{ translate('location') }}</small><br />
                                    <strong>{{ location.name }}</strong>
                                </div>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item>
                            <div class="row m-0 text-muted align-items-center">
                                <div class="col-2 col-md-auto pl-0 text-center"><i class="fas fa-clock fa-2x"></i></div>
                                <div class="col-10 col-md">
                                    <small>{{ translate('start_time') }}</small><br />
                                    <strong>{{ planning.start_time }}</strong>
                                </div>
                                <div class="col-2 col-md-auto pl-0 text-center"><i class="fas fa-clock fa-2x"></i></div>
                                <div class="col-10 col-md">
                                    <small>{{ translate('end_time') }}</small><br />
                                    <strong>{{ planning.end_time }}</strong>
                                </div>
                            </div>
                        </b-list-group-item>
                        <b-list-group-item class="p-0">
                            <div class="row m-0">
                                <div class="col-md-6 p-0">
                                    <q-input color="primary" type="text" :label="translate('name')" square dense id="name" v-model="planning.name" />
                                </div>
                                <div class="col-md-6 p-0">
                                    <q-input :label="translate('max_clients')" color="primary" square dense v-model.number="planning.max_clients" @wheel="$event.target.blur()" type="number" id="max_clients"></q-input>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-md p-0">
                                    <template>
                                        <q-input square dense readonly id="start_date" v-model="planning.start_date">
                                            <template v-slot:append>
                                                <q-icon name="event"></q-icon>
                                            </template>
                                        </q-input>
                                    </template>
                                </div>
                                <div class="col-md p-0 align-items-stretch d-flex">
                                    <label tabindex="-1" class="outlined-checkbox-bottom p-2 cursor-pointer" style="margin-left: 15px; width: calc(100% - 30px);">
                                        <span class="pull-left pr-2">
                                            <q-checkbox class="m-0" dense size="md" color="primary" v-model="planning.is_recurring" id="recurring_planning" />
                                        </span>
                                        <span>{{ translate('recurring_planning') }}</span>
                                    </label>
                                </div>
                            </div>
                        </b-list-group-item>
                        <q-slide-transition>
                            <div v-show="planning.is_recurring">
                                <div class="row m-0">
                                    <div class="col-md-6 p-0">
                                        <div class="pl-3 pt-1 pb-0 text-muted">
                                            {{ translate('end_date') }}
                                        </div>
                                        <div class="row m-0">
                                            <div class="col-auto">
                                                <q-radio class="width-auto" size="md" color="primary" v-model="end_date_selection" :val="'infinite'" id="is_infinite" :label="translate('infinite')" />
                                            </div>
                                            <div class="col-auto pr-0">
                                                <q-radio class="width-auto" size="md" color="primary" v-model="end_date_selection" :val="'predefined'" id="predefined_date" :label="translate('predefined_date')" v-on:click.native="updateEndDate"/>
                                            </div>
                                        </div>
                                        <q-slide-transition>
                                            <div v-show="end_date_selection === 'predefined'">
                                                <q-input :label="translate('end_date')" color="primary" square dense id="end_date" v-model="planning.end_date">
                                                    <template v-slot:append>
                                                        <q-icon name="event" class="cursor-pointer">
                                                            <q-popup-proxy ref="qEndDateProxy" transition-show="scale" transition-hide="scale">
                                                                <q-date @input="(val) => onUpdateEndDate(planning.end_date)" first-day-of-week="1" :options="setSelectableEndDays(planning.start_date)" v-model="planning.end_date" mask="YYYY-MM-DD" />
                                                            </q-popup-proxy>
                                                        </q-icon>
                                                    </template>
                                                </q-input>
                                            </div>
                                        </q-slide-transition>
                                    </div>
                                    <div class="col-md-6 p-0" v-if="!$q.screen.lt.sm">
                                        <q-item class="pl-0 mb-2">
                                            <q-item-section>
                                                <q-select :class="!$q.screen.lt.sm ? 'm-0' : ''" flat square color="info" :label="translate('select_timelimit')" :options="timelimit_options" v-model="planning.time_limit">
                                                    <template v-slot:prepend>
                                                        <i class="fas fa-clock"></i>
                                                    </template>
                                                </q-select>
                                            </q-item-section>
                                            <q-item-section class="p-0" side>
                                                <q-btn size="0.65rem" type="button" @click.stop.prevent="showAddTimeLimitPanel" color="primary" round dense no-caps icon="add">
                                                    <q-tooltip :offset="[10, 10]">{{ add_timelimit_title }}</q-tooltip>
                                                </q-btn>
                                            </q-item-section>
                                        </q-item>
                                    </div>
                                </div>
                            </div>
                        </q-slide-transition>
                        <b-list-group-item class="p-0 pb-2" v-if="$q.screen.lt.sm">
                            <q-item class="pl-0 pt-0 pb-0 mb-2">
                                <q-item-section>
                                    <q-select :class="!$q.screen.lt.sm ? 'm-0' : ''" flat square color="info" :label="translate('select_timelimit')" :options="timelimit_options" v-model="planning.time_limit">
                                        <template v-slot:prepend>
                                            <i class="fas fa-clock"></i>
                                        </template>
                                    </q-select>
                                </q-item-section>
                                <q-item-section class="pl-0" side>
                                    <q-btn size="0.65rem" type="button" @click.stop.prevent="showAddTimeLimitPanel" color="primary" round dense no-caps icon="add">
                                        <q-tooltip :offset="[10, 10]">{{ add_timelimit_title }}</q-tooltip>
                                    </q-btn>
                                </q-item-section>
                            </q-item>
                        </b-list-group-item>
                    </b-list-group-item>
                    <template v-if="service.providers_list && service.providers_list !== 'unavailable'">
                        <q-separator />
                        <b-list-group-item class="list-group-item-accent-info p-0">
                            <q-item @click="showAddServiceProvidersPanel(service.id)" v-ripple clickable class="align-items-center full-width justify-items-spacebetween">
                                <q-item-label>
                                    <strong>{{ translate('associate_service_providers_to_this_planning') }}</strong>
                                </q-item-label>
                                <q-space class="pr-2" />
                                <q-item-section side class="pr-0">
                                    <q-btn flat round class="shadow-2 m-0 p-1 bg-primary" dark>
                                        <i class="fas fa-2x fa-people-carry"></i>
                                    </q-btn>
                                </q-item-section>
                            </q-item>
                        </b-list-group-item>
                    </template>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="editPlanning">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import { eventBus } from '../../../../main'
import {
    QTime,
    QDate,
    QSlideTransition
} from 'quasar'
import DeletePlanning from '../forms/DeletePlanning'
import AddServiceProviders from '../forms/AddIncludedPlanningServiceProviders'
import AddTimeLimit from '../../../business/time-limits/forms/AddTimeLimit'

export default {
    name: 'EditPlanning',
    props: ['service', 'location', 'event'],
    components: {
        QTime,
        QDate,
        QSlideTransition,
    },
    created: function () {
        this.getTimeLimits()
        this.setInitialValues()
        this.getPlanning()
        this.getService()
        this.getElementsWidthHeight()
    },
    computed: {
        add_timelimit_title: function () {
            return this.translate('new_timelimit')
        },
        entity_type: function () {
            return 'planning'
        },
        patch_toggle_route: function () {
            return baseUrl + 'plannings/inactive'
        },
    },
    data: function() {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            end_date_selection: 'infinite',
            infinite_string: 'infinite',
            timelimit_options: [],
            infinite: null,
            planning: {
                name: null,
                is_recurring: true,
                is_infinite: false,
                inactive_planning_id: 0,
                is_active: true,
                start_date: '',
                end_date: '',
                start_time: '',
                end_time: '',
                max_clients: null,
                time_limit: null,
                planning_id: 0
            },
            msg: '',
        };
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        onUpdateEndDate: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qEndDateProxy.hide()
            })
        },
        onUpdateStartTime: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qStartTimeProxy.hide()
            })
        },
        onUpdateEndTime: function (newValue) {
            this.$nextTick(function () {
                this.$refs.qEndTimeProxy.hide()
            })
        },
        showAddTimeLimitPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddTimeLimit,
                props: {
                    add_item_title: this.add_timelimit_title,
                    post_route: baseUrl + 'time_limits',
                    entity_type: 'timelimit',
                }
            })
            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetTimelimitsList()
                }
            })
        },
        resetTimelimitsList: function () {
            this.timelimit_options = []
            this.getTimeLimits()
        },
        getTimeLimits: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(baseUrl + 'time_limits', {
                    headers: headers
                })
                .then(response => {
                    var timelimits = response.data.items.map(entity => {
                        var value = ''
                        if (entity.day_before_time != 'None') {
                            value = entity.day_before_time
                        }
                        if (entity.min_hours_before) {
                            value = entity.min_hours_before
                        }

                        let entity_label = this.translate(entity.limit_type) + ': ' + value
                        let array = {'value': entity.id, 'label': entity_label}
                        return array
                    })
                    this.timelimit_options = timelimits
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getTimeLimit: function () {
            var url = baseUrl + 'time_limits/' + this.planning.time_limit_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    var timelimit = response.data.item
                    var value = ''
                    if (timelimit.day_before_time != 'None') {
                        value = timelimit.day_before_time
                    }
                    if (timelimit.min_hours_before) {
                        value = timelimit.min_hours_before
                    }
                    let entity_label = this.translate(timelimit.limit_type) + ': ' + value
                    this.planning.time_limit = {'value': timelimit.id, 'label': entity_label}
                }).catch(error => {
                    var msg = error.response.data.msg;
                    if (msg != undefined) {
                        this.msg = msg;
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        getPlanning: function () {
            var url = baseUrl + 'plannings/' + this.event.planning_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.planning.name = response.data.item.name
                    this.planning.end_date = response.data.item.end_date
                    this.planning.start_date = response.data.item.start_date
                    this.planning.is_infinite = response.data.item.is_infinite ? true : false
                    this.planning.is_recurring = response.data.item.is_recurring ? true : false
                    this.end_date_selection = response.data.item.is_recurring && response.data.item.is_infinite === false ? 'predefined' : 'infinite'
                    
                    if (response.data.item.time_limit_id) {
                        this.planning.time_limit_id = response.data.item.time_limit_id
                        this.getTimeLimit()
                    }
                }).catch(error => {
                    var msg = error.response.data.msg;
                    if (msg != undefined) {
                        this.msg = msg;
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        setSelectableEndDays: function(date) {
            var dayWeekIndex = new Date(date).getDay()
            return date => new Date(date).getDay() === dayWeekIndex
        },
        updateEndDate: function () {
            this.planning.end_date = this.event.date
            console.log(this.planning.end_date)
        },
        setInitialValues: function () {
            this.planning.event_date = this.event.date
            this.planning.end_date = this.event.date
            this.planning.start_time = this.event.start_time
            this.planning.end_time = this.event.end_time
            this.planning.max_clients = this.event.max_clients
            this.planning.planning_id = this.event.planning_id
        },
        editPlanning: function () {
            //temp - need to abort if accessToken() is null
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }
            if (this.end_date_selection === 'infinite') {
                var data = {
                    'end_date': this.infinite_string
                }
            }
            if (this.end_date_selection === 'predefined') {
                var data = {
                    'end_date': this.planning.end_date
                }
            }
            if (!this.planning.is_recurring) {
                data['end_date'] = this.planning.start_date
            }
            data['time_limit_id'] = this.planning.time_limit.value
            data['max_clients'] = this.planning.max_clients
            data['name'] = this.planning.name
            
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.put(baseUrl + 'plannings/' + this.planning.planning_id, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'edit_planning_' + this.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(error => {
                    var msg = error.response.data.msg;
                    if (msg != undefined) {
                        this.msg = msg;
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        showAddServiceProvidersPanel: function (id) {
            const panelInstance = this.$showPanel({
                component: AddServiceProviders,
                props: {
                    service_id: id,
                    planning_id: this.event.planning_id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.resetMainList()
                    }
                })
        },
        showDeletePlanningPanel: function () {
            const panelInstance = this.$showPanel({
                component: DeletePlanning,
                props: {
                    planning_id:  this.event.planning_id
                }
            })

            panelInstance.promise
                .then(result => {
                    eventBus.$emit('update_plannings')
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        }) 
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        activatePlanning: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            var data = {
                'inactive_date': this.event.date,
            }
            axios.delete(baseUrl + 'plannings/' + this.event.inactive_planning_id, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    if (response.data.status) {
                        this.$toasted.success('Your status is updated', {
                            duration: this.$toasted_duration
                        })
                        this.resetMainList()
                        this.getService(this.state)
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getService: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            axios.get(baseUrl + 'services/' + this.service.id, {
                    headers: headers
                })
                .then(response => {
                    this.service.providers_list = response.data.item.providers_list ? response.data.item.providers_list : null
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        inactivatePlanning: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }

            var data = {
                'planning_id': this.event.planning_id,
                'inactive_date': this.event.date
            }

            axios.post(baseUrl + 'plannings/inactive', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'inactivate_planning_' + this.msg + key
                    var translated_message = this.translate(message)
                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error('The data was not updated', {
                            duration: this.$toasted_duration
                        })
                    }

                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}
</style>
