<template>
<div class="fit">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless>
        <b-tab class="p-0">
            <template slot="title">
                <q-icon name="fas fa-people-carry" />
            </template>
            <b-list-group class="list-group-accent">
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase cursor-pointer" @click.prevent="isHidden = !isHidden">
                    <q-icon :title="translate('Click to show or hide the included providers')" name="list" />&nbsp;
                    <strong class="position-relative pr-4" style="display: inline-block; width: auto;">
                        {{translate('providers')}}
                        <q-badge color="primary" floating>{{ providers && providers.length ? providers.length : '0' }}</q-badge>
                    </strong>
                </b-list-group-item>
                <b-list-group-item v-if="!isHidden && providers && providers.length" class="list-group-item-accent-info" :class="'list_wrapper_services-providers-' + service_id">
                    <q-item class="list-item avatar-red-hover" clickable ripple v-for="(worker,i) in providers" :key="'included-worker-' + i + worker.id">
                        <q-item-section avatar>
                            <q-avatar class="cursor-pointer shadow-2">
                                <img v-if="worker && worker.image_link" :src="worker.image_link">
                                <i v-else class="fas fa-user fa-1x text-muted"></i>
                            </q-avatar>
                        </q-item-section>
                        <q-item-section>
                            <q-item-label>{{ worker.name }}</q-item-label>
                        </q-item-section>
                        <q-item-section side>
                            <q-btn dense icon="delete" @click="showDeletePanel(worker.id)" flat color="danger" />
                        </q-item-section>
                    </q-item>
                    <div v-if="!providers">
                        <q-item class="m-0 pt-3">{{ translate('No included workers') }}</q-item>
                    </div>
                </b-list-group-item>
                <infinite-loading slot="append" @infinite="getProviders" :identifier="'infiniteLoading-' + service_id  + '-' + infProviders" />
                <q-separator />
                <b-list-group-item class="list-group-item-accent-secondary bg-light font-weight-bold text-uppercase">
                    <q-icon name="add" />&nbsp;
                    {{translate('add_provider')}}
                </b-list-group-item>
                <b-list-group-item class="list-group-item-accent-info">
                    <include-item-form :user_image_link="true" :search_placeholder="translate('find_provider')" @update_list="updateIncludedItems" :baseUrl="baseUrl" :item_id="planning_id" :post_url="post_url" :get_url="get_url" :entity_type="entity_type">
                        <q-btn slot="add_button" :size="$q.screen.lt.sm ? '0.65rem' : null" type="button" @click.stop="showAddWorkerPanel" flat dense color="dark" no-caps :label="translate('add_worker')" icon="add" />
                    </include-item-form>
                </b-list-group-item>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import {
    eventBus
} from "../../../../main"
import InfiniteLoading from 'vue-infinite-loading'

import DeleteServiceIncludedItem from './DeletePlanningServiceProvidersIncludedItem'
import IncludeItemForm from '../../../template-parts/IncludeItemForm'
import AddWorker from '../../../business/employees/forms/AddEmployee'

export default {
    name: 'AddIncludedPlanningServiceProviders',
    props: ['service_id', 'baseUrl', 'planning_id'],
    components: {
        'include-item-form': IncludeItemForm
    },
    computed: {
        entity_type: function () {
            return 'planning_service_provider'
        },
        post_url: function () {
            return 'plannings/workers'
        },
        get_url: function () {
            return 'plannings/workers/non_included'
        },
    },
    data: function () {
        return {
            isHidden: true,
            state: {},
            page_providers: 1,
            providers: [],
            infProviders: +new Date() + 1,
        }
    },
    methods: {
        showAddWorkerPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddWorker
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.resetMainList()
                }
            })
        },
        resetMainList: function () {
            this.page_providers = 1
            this.providers = []
            this.infProviders++
        },
        updateIncludedItems: function () {
            this.resetMainList()
        },
        getProviders: function ($state) {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }
            var url = this.post_url + '?page=' + this.page_providers + '&items_per_page=' + this.$items_per_page + '&planning_id=' + this.planning_id
            this.state = $state
            axios.get(baseUrl + url, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state
                    if (response.data.items && response.data.items.length > 0) {
                        if (response.data.next_page === true) {
                            this.page_providers++
                            $state.loaded()
                        } else {
                            $state.complete()
                        }
                        this.providers.push(...response.data.items.map(entity => {
                            let array = entity
                            array['image_link'] = entity.image_link ? baseUrl + entity.image_link : null
                            return array
                        }))
                    } else {
                        $state.complete()
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        showDeletePanel: function (item_id) {
            const panelInstance = this.$showPanel({
                component: DeleteServiceIncludedItem,
                props: {
                    item_id: item_id,
                    entity_type: this.entity_type,
                    post_url: this.post_url,
                    panel_title: this.translate('delete_service_included_provider'),
                    panel_message: this.translate('deleting_service_included_provider')
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.updateIncludedItems()
                        this.$emit('update_list')
                        eventBus.$emit('update_all')
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel', {})
        }
    }
}
</script>

<style lang="scss" scoped>
.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }
}
</style>
