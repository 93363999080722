<template>
<div class="animated fadeIn">
    <calendar-worker v-if="showPlanningWorker"></calendar-worker>
</div>
</template>

<script>
import PlanningsCalendarWorker from './CalendarWorker';

export default {
    name: 'Plannings',
    components: {
        'calendar-worker': PlanningsCalendarWorker
    },
    data: function () {
        return {
            enableCard: false,
        }
    },
    computed: {
        showPlanningWorker: function () {
            if (this.$appSettings.currentUserType() == 'admin') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'worker') {
                return !this.enableCard;
            } else if (this.$appSettings.currentUserType() == 'customer') {
                return this.enableCard;
            }
        },
    },
}
</script>
